<template>
    <div class="DocView">
        <div id="subdiv" v-if="doc !=null" >
            <div v-if="doc.doccontent !=null && doc.doccontent!=''">
                <div id="printdiv">
                    <Button type="warning" @click="previewcontent">打印预览</Button>
                    <Button type="primary" @click="printcontent" style="margin-left: 15px;">直接打印</Button>
                </div>
                <div id="contentdiv"  v-html="doc.doccontent"></div>
            </div>
            <div v-else ><span style="color: red;font-size: 20px;">文档内容还没有编写喔!</span></div>
        </div>
        <div v-else>正在读取文档内容...</div>
    </div>

  </template>
  
  <script>
import {getPrinters,public_print,public_print_with_dir,public_preview_with_dir} from '@/zsjs/zskjprinter.js'
  export default {
    name: "DocView",
    props: {
    },
    data(){
        return {
            userCategory:this._self.$root.$store.state.LogonInfo.userCategory,
            posturl:window.atob(this.$route.query.acturl),
            postdata:JSON.parse(window.atob(this.$route.query.actdata)),
            doc:null
        }
    },
    mounted(){
        console.log(this.posturl)
        console.log(this.postdata)
        this.querydoc()
    },
    methods:{
        querydoc(){
            this.$Loading.start();
            this.$axios({
                method:'post',
                url: this.posturl,
                data:this.postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res.data)
                        this.$Loading.finish();
                        this.doc = res.data.data
                        console.log(this.doc)
                    }
                    else{
                        this.$Loading.error();
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));   
        },
        previewcontent(){
            public_preview_with_dir("contentdiv",1,this.doc.direction);
        },
        printcontent(){
            public_print_with_dir("contentdiv",1,this.doc.direction);
        }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >
      .TemplateView {
        margin:20px auto;
        padding: 5%;
      }
    #subdiv {
        margin: 0 auto;
    }
    #printdiv {
        margin:2px auto;
        width:1100px;
        text-align: right;
    }
    #contentdiv {
        margin:0 auto;
        text-align: left;
        width:1100px;
        border: 1px solid lightgray;
    }
  </style>  